<template>
    <NuxtLink :to="href" :class="className">
      <slot />
    </NuxtLink>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";

const name = "NavLink";

const route = useRoute();

const props = defineProps({
    activeClass: {
        type: String,
        default: ''
    },
    inactiveClass: {
        type: String,
        default: ''
    },
    href: {
        type: String,
        required: true
    }
});

const className = computed(() => {
    if (props.href === ROUTES.HOME) {
        return route.path === props.href
          ? props.activeClass
          : props.inactiveClass;
    }

    return _.startsWith(route.path, props.href)
      ? props.activeClass
      : props.inactiveClass;
});

</script>
